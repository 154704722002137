footer {
    width: 100%;
    background-color:rgb(51, 51, 51);
    color: white;
    .new-window-icon {
        color: #C2C2C2;
        padding-left:  .25rem;
    }
    .list-section-wrapper {
        :first-child {
            margin-right: 3rem;
        }
    }
    .list-section {
        margin-bottom: 1rem;
        border-left: 1px solid #666;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .list-section {
        li {
            padding-left: 0;
            padding-right: 0;
        }
    }
    padding: 2rem 0;
    z-index: 500000;
    .image-logo {
        height: 2.625rem;
        width: auto;
    }
}